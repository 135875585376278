import { useEffect } from "react";
import { useRouter } from "next/router";

type useFulfillInputsFromQuery = {
  setter: (p: string) => void;
  queryParameter: string;
};

export function useFulfillInputsFromQuery({
  queryParameter,
  setter,
}: useFulfillInputsFromQuery) {
  const { query } = useRouter();

  useEffect(() => {
    const value = query[queryParameter] as string;

    if (typeof value === "string") {
      const decodedValue = decodeURIComponent(value.replace(" ", "+"));
      setter(decodedValue);
    }
  }, [query]);
}
