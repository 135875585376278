import React from "react";
import { cn } from "../../../helpers/cn";

type AuthHeaderContainerProps = React.ComponentProps<"div">;

export const AuthHeaderContainer = ({
  children,
  className,
}: AuthHeaderContainerProps) => {
  return <div className={cn(className)}>{children}</div>;
};
