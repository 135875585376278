import React from "react";

type AuthLayoutContainerProps = React.ComponentProps<"div">;

export const AuthLayoutContainer = ({ children }: AuthLayoutContainerProps) => {
  return (
    <div className="h-full flex flex-col lg:flex-row container mx-auto my-10 max-w-[80rem]">
      {children}
    </div>
  );
};
