import React from "react";
import {cn} from "../../../helpers/cn";
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

type AuthHeaderSubtitleProps = React.ComponentProps<'h2'>

export const AuthHeaderSubtitle = ({children, className}: AuthHeaderSubtitleProps) => {
    const colors = useSelector((state: RootState) => state.theme.componentColors,);

    return <Typography
        sx={{
            color: colors.surface.onSurface,
        }}
        variant={"h2"}
        className={cn("text-base font-normal", className)}
    >
        {children}
    </Typography>
}