import React from "react";
import {cn} from "../../../helpers/cn";
import {Typography} from "@mui/material";
import {RootState} from "../../../redux/store";
import {useSelector} from "react-redux";

type AuthHeaderTitleProps = React.ComponentProps<'h2'>

export const AuthHeaderTitle = ({children, className}: AuthHeaderTitleProps) => {
    const colors = useSelector((state: RootState) => state.theme.componentColors,);

    return <Typography
        sx={{
            color: colors.surface.onSurface,
        }}
        variant={"body1"} className={cn('text-3xl font-medium', className)}>
        {children}
    </Typography>
}