import { CardProps } from "material-ui";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { cn } from "../../../../../helpers/cn";

type AuthLayoutLeftContainerCardProps = CardProps;

export const AuthLayoutLeftContainerCard = ({
  children,
  className,
}: AuthLayoutLeftContainerCardProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Card
      sx={{
        backgroundColor: colors.surface.surfContainer,
      }}
      className={cn(
        "flex items-center justify-center lg:rounded-l-2xl lg:rounded-r-none lg:w-1/2 p-10 overflow-scroll relative",
        className,
      )}
    >
      {children}
    </Card>
  );
};
