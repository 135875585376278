import React, { createContext, Dispatch, useContext, useReducer } from "react";

export enum PasswordStrength {
  NO_PASSWORD = 0,
  WEAK = 1,
  AVERAGE = 2,
  GOOD = 3,
  STRONG = 4,
}

type RegisterPasswordStrengthProviderContextType = {
  state: RegisterPasswordStrengthState;
  dispatch: Dispatch<RegisterPasswordStrengthAction>;
};

export const RegisterPasswordStrengthContext =
  createContext<RegisterPasswordStrengthProviderContextType | null>(null);

export const useRegisterPasswordStrengthContext = () => {
  const context = useContext(RegisterPasswordStrengthContext);

  if (!context) {
    throw new Error(
      "useRegisterPasswordStrengthContext can only be accessed within RegisterFormProvider",
    );
  }

  return context;
};

type RegisterPasswordStrengthState = {
  color?: string;
  strength?: PasswordStrength;
};

export enum RegisterPasswordStrengthActions {
  SET_COLOR,
  SET_PASSWORD_STRENGTH,
}

export type RegisterPasswordStrengthAction =
  | {
      type: RegisterPasswordStrengthActions.SET_COLOR;
      payload: string;
    }
  | {
      type: RegisterPasswordStrengthActions.SET_PASSWORD_STRENGTH;
      payload: PasswordStrength;
    };

const registerPasswordStrengthReducer = (
  state: RegisterPasswordStrengthState,
  action: RegisterPasswordStrengthAction,
): RegisterPasswordStrengthState => {
  switch (action.type) {
    case RegisterPasswordStrengthActions.SET_COLOR:
      return { ...state, color: action.payload };
    case RegisterPasswordStrengthActions.SET_PASSWORD_STRENGTH:
      return {
        ...state,
        strength: action.payload,
      };
  }
};

const initialState: RegisterPasswordStrengthState = {
  color: null,
  strength: null,
};

export const RegisterPasswordStrengthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    registerPasswordStrengthReducer,
    initialState,
  );

  return (
    <RegisterPasswordStrengthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </RegisterPasswordStrengthContext.Provider>
  );
};
