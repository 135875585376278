import { TextField, TextFieldProps } from "@mui/material";
import { cn } from "../../../../helpers/cn";
import React from "react";

export type AppTextInputProps = { endIcon?: React.ReactNode } & TextFieldProps;

export const AppTextInput = ({
  className,
  placeholder,
  onChange,
  type,
  label,
  error,
  helperText,
  endIcon,
  value,
}: AppTextInputProps) => {
  return (
    <TextField
      type={type}
      onChange={onChange}
      className={cn("rounded-lg w-full", className)}
      placeholder={placeholder}
      label={label}
      error={error}
      helperText={helperText}
      value={value}
      InputProps={{
        endAdornment: endIcon,
      }}
    />
  );
};
