import React, { Dispatch, SetStateAction } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export type AuthShowPasswordProps = {
  showPassword: boolean;
  setShowPassword: Dispatch<SetStateAction<boolean>>;
} & React.ComponentProps<"div">;

export const AuthShowPassword = ({
  showPassword,
  setShowPassword,
}: AuthShowPasswordProps) => {
  return (
    <div
      onClick={() => {
        setShowPassword((prev) => !prev);
      }}
      className={"w-10 pl-3 flex items-center justify-center cursor-pointer"}
    >
      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </div>
  );
};
