import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Typography } from "@mui/material";
import { Autoplay, Pagination } from "swiper/modules";

const content = [
  {
    title: "Expert coaches",
    description:
      "Featured coaches are Vetted for experience & expertise. Choose from Pro or Verified coaches.",
  },
  {
    title: "Proven methods",
    description:
      "Benefit from training methodologies and techniques used by elite athletes and teams to achieve peak performance.",
  },
  {
    title: "Seamless swaps",
    description:
      "Easily switch between different training plans and coaches at any time with a POD1UM membership.",
  },
];

export const AuthLayoutSlider = () => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      key={"auth-layout-slider"}
      id={"auth-layout-slider"}
      autoplay={true}
      slidesPerView={1}
      width={550}
      spaceBetween={50}
      pagination={{
        dynamicMainBullets: 2,
        dynamicBullets: true,
      }}
      direction={"horizontal"}
      className={"hidden lg:block overflow-hidden w-[35rem] pb-20"}
    >
      {content.map((item, i) => (
        <SwiperSlide key={i} className={"cursor-pointer"}>
          <div className={"flex flex-col items-center gap-6"}>
            <Typography
              variant={"h2"}
              className={"text-[2.5rem] font-bold text-center"}
            >
              {item.title}
            </Typography>
            <Typography className={"text-center font-medium text-lg"}>
              {item.description}
            </Typography>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
