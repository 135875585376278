import {
  PasswordStrength,
  RegisterPasswordStrengthActions,
  useRegisterPasswordStrengthContext,
} from "../../components/global/auth/register/RegisterPasswordStrength/RegisterPasswordStrengthProvider";
import { useEffect } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

export const useGetCurrentPasswordStrengthColor = () => {
  const { state, dispatch } = useRegisterPasswordStrengthContext();
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  useEffect(() => {
    let color = colors.outline.outline;
    const currentStrength = state.strength;

    if (currentStrength === PasswordStrength.NO_PASSWORD) {
      color = colors.outline.outline;
    }

    if (currentStrength === PasswordStrength.WEAK) {
      color = colors.error.error;
    }

    if (currentStrength === PasswordStrength.AVERAGE) {
      color = "#FBAD4E";
    }

    if (currentStrength === PasswordStrength.GOOD) {
      color = "#00B98B";
    }

    if (currentStrength === PasswordStrength.STRONG) {
      color = "#1D7963";
    }

    dispatch({
      type: RegisterPasswordStrengthActions.SET_COLOR,
      payload: color,
    });
  }, [state.strength]);

  return state.color;
};
