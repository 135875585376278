import { AppIconButton } from "../ui/buttons";
import { AppIconButtonProps } from "../ui/buttons/AppIconButton";
import { cn } from "../../../helpers/cn";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useRouter } from "next/router";

export const AuthBackButton = (props: AppIconButtonProps) => {
  const router = useRouter();

  const authBackButtonHandler = () => {
    void router.back();
  };

  return (
    <AppIconButton
      onClick={authBackButtonHandler}
      className={cn("absolute top-4 left-4 lg:left-[21%]", props.className)}
      {...props}
    >
      <ChevronLeftIcon />
    </AppIconButton>
  );
};
