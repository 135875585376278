import { useMemo } from "react";

type UseGetCurrentPasswordStrengthDataType = {
  password: string;
};

export const useGetCurrentPasswordStrength = ({
  password,
}: UseGetCurrentPasswordStrengthDataType) => {
  const passwordCriteria = useMemo(
    () => [
      hasSufficientLength(password),
      hasNumbers(password),
      hasUpperAndLowercase(password),
      hasNumbersAndSpecialCharacters(password),
    ],
    [password],
  );

  const reducedValuesFromPasswordCriteria = useMemo(
    () =>
      passwordCriteria.reduce((acc, current) => (current ? acc + 1 : acc), 0),
    [passwordCriteria],
  );
  return reducedValuesFromPasswordCriteria;
};

function hasNumbers(password: string): boolean {
  return /\d/.test(password);
}

function hasUpperAndLowercase(password: string): boolean {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(password);
}

function hasNumbersAndSpecialCharacters(password: string): boolean {
  return /(?=.*\d)(?=.*[\W_])/.test(password);
}

function hasSufficientLength(password: string): boolean {
  return password.length >= 6 || password.length > 0;
}
