import React from "react";
import { AuthLayoutLeftContainerCard } from "./AuthLayoutLeftContainer";
import { AuthLayoutContainer } from "./AuthLayoutContainer";
import { AuthLayoutRightContainerCard } from "./AuthLayoutRightContainer/AuthLayoutRightContainerCard";
import { AuthLayoutSlider } from "./AuthLayoutRightContainer";
import { MaterialThemeProvider } from "../../layout/material/MaterialThemeProvider";
import { ModalProvider } from "../../modals";
import { SnackbarProvider, SnackbarWrapper } from "../../snackbar";

type AuthLayoutProps = React.ComponentProps<"div">;

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <MaterialThemeProvider>
      <ModalProvider>
        <SnackbarProvider>
          <SnackbarWrapper>
            <AuthLayoutContainer>
              <AuthLayoutLeftContainerCard>
                {children}
              </AuthLayoutLeftContainerCard>

              <AuthLayoutRightContainerCard>
                <AuthLayoutSlider />
              </AuthLayoutRightContainerCard>
            </AuthLayoutContainer>
          </SnackbarWrapper>
        </SnackbarProvider>
      </ModalProvider>
    </MaterialThemeProvider>
  );
};
