import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Card } from "@mui/material";
import React from "react";

type AuthLayoutRightContainerCardProps = React.ComponentProps<"div">;

export const AuthLayoutRightContainerCard = ({
  children,
}: AuthLayoutRightContainerCardProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Card
      sx={{
        backgroundColor: colors.surface.surfaceDim,
      }}
      className="hidden lg:block lg:rounded-r-2xl lg:rounded-l-none lg:w-1/2 bg-none relative"
    >
      <img
        className={
          "absolute z-10 transform -translate-x-1/2 -top-40 -right-80 h-[40rem]"
        }
        src={"/images/pod1um-blue-icon.svg"}
        alt="Pod1um logo | Pod1um"
      />

      {/*<div*/}
      {/*  className="w-full h-full opacity-70 absolute top-0 right-0"*/}
      {/*  style={{ backgroundColor: colors.surface.surfaceDim }}*/}
      {/*/>*/}

      {/*<video autoPlay muted loop preload={"none"} className={"h-full"}>*/}
      {/*  <source src="/videos/pod1um-signup-video.webm" type="video/webm" />*/}
      {/*</video>*/}

      <div className={"bottom-0 absolute left-1/2 -translate-x-1/2"}>
        {children}
      </div>
    </Card>
  );
};
